<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('common.onlineStore')"
		:tabs="$options.tabs"
		:current-tab="currentTab"
		@change="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@close="$emit('close')"
		/>
	</ZyroPopupCard>
</template>

<script>
import EditBlockTabBackground from '@/components/builder-controls/edit-block/EditBlockTabBackground.vue';
import EditBlockPadding from '@/components/builder-controls/reusable-controls/block/EditBlockPadding.vue';
import i18n from '@/i18n/setup';

const tabs = [
	{
		id: 'layout',
		title: i18n.t('common.layout'),
		component: 'EditBlockPadding',
	},
	{
		id: 'background',
		title: i18n.t('common.background'),
		component: 'EditBlockTabBackground',
	},
];

export default {
	components: {
		EditBlockTabBackground,
		EditBlockPadding,
	},
	props: {
		startTabId: {
			type: String,
			default: '',
		},
	},
	data() {
		return { currentTab: null };
	},
	created() {
		this.currentTab = this.$options.tabs
			.find((tab) => tab.id === this.startTabId)
			|| this.$options.tabs[0];
	},
	tabs,
};
</script>
